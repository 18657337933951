@import "../../../../resources/styles/media";
@import "../../../../resources/styles/colors";

.container {
    margin: 1rem 0 0 0;
}

.noReleases {
    height: auto;
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
    line-height: 1.5;
    color: @secondaryText;
}

.releaseVersionLink {
    font-size: 1.125rem;
    margin: 0;
    padding: 0;
}

.releaseDetailsLayout {
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
}

.releaseDetailsLayoutContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    // https://www.w3.org/TR/css-flexbox-1/#min-size-auto
    // This prevents the child from growing larger than the parent flexbox container if the childs content is large
    min-width: 0;
}

.releaseDetailsSidebarContent {
    max-width: 260px;
    flex-grow: 1;
}

.subTitle {
    color: @secondaryText;
}

.releaseHeader {
    font-size: 0.875rem;
}

.releaseHeaderVersion {
    font-size: 1.25rem;
}

.dataTableHeaderColumn, .dataTableRowColumn {
    max-width: 16rem;
}

.dataTableRowColumn:first-child {
    width: 9.375rem;
}

.dataTableRowColumn:not(:first-child) {
    width: 16rem;
}

@media (max-width: @screen-sm) {
    .releaseDetailsLayout {
        flex-direction: column;
    }

    .releaseDetailsSidebarContent {
        max-width: 100%;
    }

    .subTitle {
        padding-left: 1rem;
    }

    .marginTopBottom {
        margin: 0.5rem 0;
    }
}

.releaseNoteLayout, .releaseArtifactsLayout, .releaseBuildInformationLayout {
    font-size: 0.875rem;
}
.releasePackagesLayout{
    font-size: 0.875rem;
    p {
        margin: 0.5rem !important;
    }
}

.releasesTableContainer {
    .filter {
        margin-left: 1rem;
        max-width: 260/16rem;
    }
    .releaseNotesContainer {
        cursor: auto;
    }
    .collapseButton {
        margin-left: -0.5rem;
    }
    .notesColumn {
        width: 40rem;
    }
}

.artifactTime {
    margin-left: 1rem;
    display: inline-block;
}

.gitRefChip {
    margin: 4px;
}
@import "../../../../../resources/styles/variables";
@import "../../../../../resources/styles/colors";
@import "../../../../../resources/styles/media";

.deploymentPart {
    min-height: 5.1rem;
}

.divider {
    display: flex;
    align-items: center;
}

.actionSummary {
    display: flex;
    flex-flow: row;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}

.disabled * {
    color: @secondaryText;

    svg {
        opacity: 0.5;
    }
}

.logo {
    order: 1;
}

.content {
    flex: 4 0;
    order: 2;
    margin-left: 1rem;
}

.contextualActions {
    order: 3;
}

.actions {
    margin-left: 4.1rem;
    padding-left: 0;
}

.steps {
    padding-left: 1rem;
}

.index {
    margin-right: 1rem;
    font-size: 17/16rem;
}

.container {
    display: flex;
    gap: 16px;
}

.contentHeader {
    margin-bottom: 0.5rem;
    font-weight: @semibold;
}

.contentBody {
    font-size: 14/16rem;
}

.environment {
    text-align: right;
    padding-bottom: 0.25rem;
}

.scriptModulesStatus {
    font-size: 14/16rem;
    margin: 0;
}

.includedScriptModules {
    list-style: disc inside;
    color: @mainText;
}

.lifeCycleName {
    font-size: 1rem;
    font-weight: @bold;
    margin-right: 1rem;
}

.noteList {
    list-style-type: disc;
    padding: 0 1rem;
    max-width: 36rem;
}

.stepTitle {
    display: flex;
    flex-direction: column;

    .stepNumber {
        padding-right: 0.5rem;
    }

    .stepType {
        text-transform: uppercase;
        color: @secondaryText;
        font-size: 0.75rem;
        line-height: 1;
    }
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .container {
        width: 80%;
    }
    .images {
        width: 60%;
        align-items: center;
        text-align: center;
        img {
            .conceptImage();
        }
        div {
            text-align: center;
            @media (max-width: @screen-md) {
                text-align: left;
            }
        }
        @media (max-width: @screen-md) {
            width: 100%;
        }
    }
    @media (max-width: @screen-md) {
        flex-direction: column;
    }
}

.col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    img {
        .conceptImage();
    }
}

.conceptImage() {
    height: 5rem;
    width: auto;
    margin: 0.5rem 0;
}

.parentStepIcon {
    background-color: @parentStepBackground;
    border-radius: 5rem;
}
